import config from 'constant';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useCommon from 'hooks/useCommon';
import useGetPosSetting from 'hooks/useGetPosSetting';
import useInitProducts from 'hooks/useInitProducts';
import { setInitSync, setSyncPayload } from 'redux/reducer/registers';
import { setStructSetting } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import request from 'services/http/request';
import { db } from 'services/indexdb/connection';
import { ICustomerInfo, IDiscount, PriceBook, SerialNumber } from 'types/common.types';
import { IListStockV2, IProductList } from 'types/products.types';

import useNotification from './useNotification';

export default function useDownloadManager() {
  const dispatch = useAppDispatch();
  const location = useAppSelector((state) => state.register.location);

  const { notification } = useNotification();
  const { getPosSetting } = useGetPosSetting();
  const { getAllProduct, getItemStock, mappingStockToItem } = useInitProducts();
  const {
    getPresets,
    getSalesmen,
    getPayments,
    getCourier,
    getContactCategory,
    getMarketplace,
    getAuthorizedUser,
  } = useCommon();

  const fetchData = async (action: string, cursor = '0') => {
    const qs = new URLSearchParams({
      action,
      cursor,
      pageSize: '20',
    }).toString();
    const response = await request.get(`/sales/pos/v3/location/${location?.location_id}/download?${qs}`);

    return response.data;
  };

  const clearIndexDB = async (removeCommonDataOnly = false) => {
    try {
      await Promise.all([
        db.presetsdiscount.clear(),
        db.presetstax.clear(),
        db.salesmen.clear(),
        db.cart.clear(),
        db.authorizeduser.clear(),
        db.contactcategory.clear(),
        db.courier.clear(),
        db.marketplace.clear(),
        db.paymentmethod.clear(),
      ]);
      if (!removeCommonDataOnly) {
        await Promise.all([
          db.products.clear(),
          db.customer.clear(),
          db.cart.clear(),
          db.discount.clear(),
          db.pricebook.clear(),
          db.serialnumber.clear(),
          db.batchnumber.clear(),
        ]);
      }
    } catch (err: any) {
      console.error('Error during clearing indexDB:', err);
    }
  };

  const saveData = async (stepName: string, data: Record<string, any>) => {
    try {
      if (stepName === 'discount') {
        await db.discount.bulkAdd(data as IDiscount[]);
      } else if (stepName === 'pricebook') {
        await db.pricebook.bulkAdd(data as PriceBook[]);
      } else if (stepName === 'serialnumber') {
        await db.serialnumber.bulkAdd(data as SerialNumber[]);
      } else if (stepName === 'batchnumber') {
        await db.batchnumber.bulkAdd(data as SerialNumber[]);
      } else if (stepName === 'contact') {
        await db.customer.bulkAdd(data as ICustomerInfo[]);
      } else if (stepName === 'items') {
        await getAllProduct({ data: data as IProductList[], totalCount: 0 });
      } else if (stepName === 'stock') {
        await getItemStock(data as IListStockV2[]);
      } else {
        return Promise.reject(new Error('Invalid step name'));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const downloadStep = async (message: string, stepName: string) => {
    let cursor = 0;
    let hasMore = true;
    let downloadedItems = 0;

    console.log(`[INFO] ${message}`);
    dispatch(setSyncPayload({ stepDownload: stepName }));

    while (hasMore) {
      const data = await fetchData(stepName, cursor.toString());
      if (data.data.length === 0 && !data.has_more) {
        console.log(`[INFO] ${message} - no more data skip to next step`);
        break;
      }

      await saveData(stepName, data.data);
      downloadedItems += data.data.length;
      console.log(`[INFO] ${message} - cursor ${downloadedItems}`);
      hasMore = data.has_more === true ? true : false;
      cursor = data.next_cursor !== null ? data.next_cursor : cursor;

      dispatch(setSyncPayload({ pageDownload: cursor }));
    }
  };

  const mergeItemsWithStock = async () => {
    dispatch(setSyncPayload({ stepDownload: 'merge-stock' }));
    await mappingStockToItem();
  };

  const downloadCommonData = async () => {
    const data = await commonRequest.structPrint(location?.location_id as number);
    dispatch(setStructSetting(data));
    await Promise.all([
      getPosSetting(),
      getPresets('DISCOUNT'),
      getPresets('TAX'),
      getSalesmen(),
      getContactCategory(),
      getMarketplace(),
      getPayments(),
      getCourier(),
      getAuthorizedUser({
        location_id: location?.location_id as number,
        permission_id: [
          config.ACL_AUTH_CANCEL,
          config.ACL_AUTH_RETURN,
          config.ACL_AUTH_DP,
          config.ACL_AUTH_REPRINT,
          config.ACL_AUTH_RECEIPT_EMAIL,
        ],
      }),
    ]);
  };

  const startDownloadCommonData = async () => {
    console.log('[INFO] Downloading common data after opening cash register');
    await downloadCommonData();
    // init customer for pos after opening cash register
    await downloadDefaultContact();
    console.log('[INFO] Finished downloading common data after opening cash register');
  };

  const startDownloadData = async () => {
    try {
      console.log('[INFO] Start downloading data from server');
      await clearIndexDB();
      await downloadDefaultContact();
      await downloadCommonData();
      await downloadStep('Downloading discount', 'discount');
      await downloadStep('Downloading pricebook', 'pricebook');
      await downloadStep('Downloading contact', 'contact');
      await downloadStep('Downloading serial numbers', 'serialnumber');
      await downloadStep('Downloading batch numbers', 'batchnumber');
      await downloadStep('Downloading items', 'items');
      await downloadStep('Downloading stocks', 'stock');
      await mergeItemsWithStock();
      dispatch(setInitSync(false));
      dispatch(setSyncPayload({ stepDownload: 'done' }));

      console.log('[INFO] All data downloaded and processed successfully');
    } catch (error) {
      dispatch(setSyncPayload({ stepDownload: '' }));
      dispatch(setInitSync(false));
      notification('', 'Terjadi kesahalan saat download data. Harap coba lagi', 'error', 3000);
      console.error('Error during download:', error);
    }
  };

  const downloadDefaultContact = async () => {
    await db.customer.add({
      contact_id: -1,
      contact_name: 'Pelanggan Umum',
      email: '',
      is_dropshipper: false,
      phone: '',
      s_address: '',
      s_area: '',
      s_city: '',
      s_post_code: '',
      s_province: '',
      is_loyalty_member: false,
      contact_type: 0,
      category_id: -1,
      store_credit: '',
    });
  };

  return {
    startDownloadData,
    clearIndexDB,
    startDownloadCommonData,
    downloadDefaultContact,
  };
}
