import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import SyncIcon from 'components/icons/SyncIcon';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ModalRequest from 'components/modal/ModalRequest';
import { CustomLineTab } from 'components/tabs';
import { Title } from 'components/typography';
import { alert, modal } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { useGetTransaction, useNotification, useOrder, useReturn } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Retur from 'pages/device-transaction/return';
import Sales from 'pages/device-transaction/sales';
import * as React from 'react';
import { setTabs } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';
import { DetailOrderTransaction } from 'types';
import { IReturnList } from 'types/return.types';
import { IDetailTransaction } from 'types/transaction.types';
import { debounce } from 'utils';

const DeviceTransaction: React.FC = () => {
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [searchOffline, setSearchOffline] = React.useState<string>('');
  const [searchReturnOffline, setSearchReturnOffline] = React.useState<string>('');
  const [detailOrder, setDetailOrder] = React.useState<DetailOrderTransaction>({} as DetailOrderTransaction);
  const [isLoadingResend, setIsLoadingResend] = React.useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
  const [deleteAll, setDeleteAll] = React.useState<boolean>(false);
  const [isSync, setIsSync] = React.useState<boolean>(false);

  const tabsIndex = useAppSelector((state) => state.commons.tabsIndex);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const location = useAppSelector((state) => state.register.registerInfo);
  const { isOpen: isOpenRequest, onOpen: onOpenRequest, onClose: onCloseRequest } = useDisclosure();
  const {
    isOpen: isOpenDeleteConfirmation,
    onOpen: onOpenDeleteConfirmation,
    onClose: onCloseDeleteConfirmation,
  } = useDisclosure();
  const { sendOrder } = useOrder();
  const { sendOrderReturn } = useReturn();
  const { notification } = useNotification();
  const { sendFailTransaction } = useGetTransaction();
  const dispatch = useAppDispatch();

  const searchItems = debounce(async (value: string) => {
    if (tabsIndex === 0) {
      setSearchOffline(value);
    } else {
      setSearchReturnOffline(value);
    }
    setPage(1);
  }, 500);

  const deleteOrder = async (key: number | null, isReturn: boolean) => {
    try {
      setIsLoadingDelete(true);
      await commonRequest.logPos(
        deleteAll ? 'DELETE-ALL-TRANSACTION-DEVICE' : 'DELETE-TRANSACTION-DEVICE',
        JSON.stringify(
          deleteAll
            ? {
                location_id: location?.location_id,
                register_id: location?.register_id,
                closure_id: location?.last_closure.closure_id,
              }
            : detailOrder
        ),
        true
      );
      if (deleteAll) {
        await orders.clearByKey(deleteAll, key);
        await returnOrder.clearByKey(deleteAll, key);
      } else {
        if (isReturn) await returnOrder.clearByKey(deleteAll, key);
        else await orders.clearByKey(deleteAll, key);
      }
      onCloseDeleteConfirmation();
    } catch (error) {
      notification('', alert.error_send_order, 'error', 3000);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const transactionList = useLiveQuery<IDetailTransaction[]>(() => {
    return orders.get(searchOffline, 0);
  }, [searchOffline, tabsIndex]) as IDetailTransaction[];

  const returnList = useLiveQuery<IReturnList[]>(() => {
    if (tabsIndex === 1) {
      return returnOrder.get(searchReturnOffline);
    }

    return [];
  }, [searchReturnOffline, tabsIndex]) as IReturnList[];

  const updateStatusSync = React.useCallback(async () => {
    onCloseRequest();
    if (detailOrder.is_return && detailOrder.is_return === 1) {
      await returnOrder.updateStatusReturn(detailOrder.pos_return_no);
    } else {
      await orders.updateStatus(detailOrder.salesorder_no);
    }
  }, [detailOrder]);

  const resendRequest = React.useCallback(
    async (payload: string) => {
      try {
        setIsLoadingResend(true);
        if (detailOrder.is_return && detailOrder.is_return === 1) {
          await sendOrderReturn(JSON.parse(payload));
        } else {
          await sendOrder(JSON.parse(payload));
        }
        onCloseRequest();
      } catch (error) {
        notification('', alert.error_send_order, 'error', 3000);
      } finally {
        setIsLoadingResend(false);
      }
    },
    [detailOrder]
  );

  const handleSyncTransaction = async () => {
    try {
      setIsSync(true);
      await sendFailTransaction();
      setPage(1);
      setPageSize(15);
    } catch (error) {
      notification('', alert.error_send_order, 'error', 3000);
    } finally {
      setIsSync(false);
    }
  };

  const disabledButtonSync = React.useMemo(() => {
    if (!isOnline) return true;
    const filterTransaction = transactionList ? transactionList.filter((order) => order.is_paid !== 1) : [];
    const filterReturn = returnList ? returnList.filter((retur) => retur.is_paid !== 1) : [];
    if (filterTransaction.length <= 0 && tabsIndex === 0) return true;
    if (filterReturn.length <= 0 && tabsIndex === 1) return true;
    return false;
  }, [isOnline, tabsIndex, transactionList, returnList]);

  const disabledButtonDelete = React.useMemo(() => {
    if (!isOnline) return true;
    if (tabsIndex === 0) {
      if (!transactionList) return true;
      else {
        if (transactionList.length <= 0) return true;
      }
    }
    if (tabsIndex === 1) {
      if (!returnList) return true;
      else {
        if (returnList.length <= 0) return true;
      }
    }
    return false;
  }, [isOnline, tabsIndex, transactionList, returnList]);

  return (
    <>
      <Box width={{ base: 'full', sm: 'full' }} padding={5}>
        <Box position='relative' h='full' bg='white' rounded='lg'>
          <Flex alignItems='center' justifyContent='space-between'>
            <Title fontSize='18px'>Daftar Transaksi Perangkat</Title>
            <HStack>
              <Button
                variant='outline'
                size='sm'
                px={7}
                borderRadius='8px'
                borderColor='jubelio.primary'
                color='jubelio.primary'
                leftIcon={<DeleteIcon color='jubelio.primary' />}
                onClick={() => {
                  setDeleteAll(true);
                  onOpenDeleteConfirmation();
                }}
                isDisabled={disabledButtonDelete}
              >
                <Text fontSize='14px'>Hapus Semua</Text>
              </Button>
              <Button
                variant='outline'
                size='sm'
                px={7}
                borderRadius='8px'
                color='jubelio.black'
                leftIcon={<SyncIcon />}
                onClick={handleSyncTransaction}
                disabled={disabledButtonSync}
                isDisabled={disabledButtonSync}
                isLoading={isSync}
                loadingText='Sinkronisasi...'
              >
                <Text fontSize='14px'>Sinkron Ulang</Text>
              </Button>
            </HStack>
          </Flex>
          <Divider my={3} borderColor='system.outline'></Divider>
          <Tabs
            onChange={(e: number) => {
              setPage(1);
              setPageSize(15);
              dispatch(setTabs({ name: 'tabsIndex', index: e }));
            }}
            defaultIndex={tabsIndex}
          >
            <TabList borderBottomWidth='0.5px'>
              <CustomLineTab>Penjualan</CustomLineTab>
              <CustomLineTab>Retur</CustomLineTab>
            </TabList>
            <TabPanels>
              {/* Sales Tab */}
              <TabPanel>
                <Sales
                  searchOnline={searchOffline ?? ''}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  onSearch={searchItems}
                  setPageSize={setPageSize}
                  setDetailOrder={setDetailOrder}
                  transactionsList={transactionList || []}
                  openPopupSync={onOpenRequest}
                  onOpenConfirmationDelete={onOpenDeleteConfirmation}
                  setDeleteAll={setDeleteAll}
                />
              </TabPanel>
              {/* Return Tab */}
              <TabPanel>
                <Retur
                  searchOnline={searchReturnOffline}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  onSearch={searchItems}
                  setPageSize={setPageSize}
                  setDetailOrder={setDetailOrder}
                  transactionsList={returnList || []}
                  openPopupSync={onOpenRequest}
                  onOpenConfirmationDelete={onOpenDeleteConfirmation}
                  setDeleteAll={setDeleteAll}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>

      <ModalRequest
        isOpen={isOpenRequest}
        onClose={onCloseRequest}
        orderNo={
          detailOrder.is_return && detailOrder.is_return === 1 && !detailOrder.hasRetur
            ? detailOrder.pos_return_no
            : detailOrder.salesorder_no
        }
        request={JSON.stringify(
          detailOrder.request_payload && JSON.parse(detailOrder.request_payload),
          null,
          2
        )}
        updateStatus={updateStatusSync}
        errorMessage={detailOrder.errorMessage}
        saveRequest={resendRequest}
        isLoading={isLoadingResend}
        showResendButton={true}
        showStatusSyncButton={false}
      />

      <ModalConfirmation
        title={deleteAll ? modal.title_delete_all_transaction : modal.title_delete_transaction}
        isOpen={isOpenDeleteConfirmation}
        onClose={onCloseDeleteConfirmation}
        subtitle={deleteAll ? modal.subtitle_delete_all_transaction : modal.subtitle_delete_transaction}
        cancelText={modal.cancel_retur_text}
        okText={modal.ok_delete_text}
        onSubmit={() =>
          deleteOrder(detailOrder.key || null, detailOrder.is_return === 1 && !detailOrder.hasRetur)
        }
        loadingSubmit={isLoadingDelete}
        isOnline={isOnline}
      />
    </>
  );
};

export default DeviceTransaction;
